import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function DTwentyIcon(props) {
  return (
    <SvgIcon>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#C8B874" stroke="none">
<path d="M2062 4260 c-271 -467 -492 -852 -492 -855 0 -3 445 -5 990 -5 545 0
990 2 990 5 0 11 -984 1705 -990 1705 -3 0 -227 -382 -498 -850z"/>
<path d="M1258 4393 c-515 -295 -935 -540 -933 -543 6 -9 1004 -424 1008 -419
2 2 189 326 416 719 228 393 422 730 433 748 11 17 18 32 16 31 -2 0 -425
-242 -940 -536z"/>
<path d="M2937 4900 c11 -19 206 -357 433 -750 227 -393 414 -716 415 -718 4
-5 965 441 965 447 0 4 -397 234 -882 511 -486 277 -899 513 -918 524 l-34 21
21 -35z"/>
<path d="M4430 3490 c-261 -121 -487 -225 -503 -232 l-27 -13 505 -800 c278
-439 510 -804 515 -809 7 -7 10 341 10 1032 0 827 -3 1042 -12 1041 -7 0 -227
-99 -488 -219z"/>
<path d="M190 2644 c0 -859 2 -1016 14 -1004 19 21 1006 1587 1006 1597 0 8
-951 407 -998 420 l-22 5 0 -1018z"/>
<path d="M1599 3108 c23 -40 247 -448 498 -905 252 -458 460 -833 463 -833 3
0 211 375 463 833 251 457 475 865 498 905 l40 72 -1001 0 -1001 0 40 -72z"/>
<path d="M827 2219 c-296 -468 -539 -856 -540 -862 -1 -8 300 -24 1041 -57
573 -25 1042 -44 1042 -42 0 9 -996 1812 -1000 1812 -3 0 -248 -383 -543 -851z"/>
<path d="M3247 2164 c-274 -498 -497 -907 -495 -908 7 -5 2073 87 2080 93 7 6
-1066 1714 -1080 1719 -4 1 -231 -406 -505 -904z"/>
<path d="M501 1116 c2 -2 442 -254 977 -559 l972 -556 0 520 0 519 -47 0 c-27
0 -457 18 -958 40 -977 43 -950 42 -944 36z"/>
<path d="M3588 1077 l-918 -41 0 -517 0 -518 973 556 c534 305 974 557 976
559 7 6 -65 4 -1031 -39z"/>
</g>
</svg>
    </SvgIcon>
  );
}

export default DTwentyIcon;